.container.portfolio__container {
    position: relative;
}

.swiper-pagination-bullets {
    z-index: 1; /* Ensure a higher z-index for pagination dots */
}

.portfolio__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg);
    padding: 1.5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    overflow: hidden; /* Ensure the container clips the image properly */
}

.portfolio__item:hover {
    border-color: var(--color-header);
    background: transparent;
}

.portfolio__item-image {
    width: 100%; /* Ensure the image takes up 100% width of the container */
    height: auto; /* Maintain the aspect ratio */
    border-radius: 1.5rem;
    transition: transform var(--transition); /* Apply transition to the transform property */
}

.portfolio__item:hover .portfolio__item-image {
    transform: scale(0.8); /* Adjust the scale value as needed */
}

.portfolio__item h3 {
    display: flex;
    justify-content: center;
    margin: 1rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

/*-----------------------Media Queries Medium---------------*/
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/*-----------------------Media Queries Small---------------*/
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
