@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  /* text-align: center; */
}

:root {
  --color-bg: #124559;
  --color-bg-variant: #718e74;
  --color-container: #718e7459;
  --color-header: #e7a42b; /*this is used on all text*/
  --color-text: #01161e;
  --color-white: #ffff;
  --color-light: rgb(255, 255, 255, 0.6);

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-ms: 90%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Lato", sans-serif;
  background-image: linear-gradient(
    40deg,
    hsl(197deg 66% 21%) 0%,
    hsl(197deg 69% 18%) 38%,
    hsl(197deg 74% 15%) 54%,
    hsl(197deg 78% 12%) 64%,
    hsl(197deg 84% 14%) 73%,
    hsl(196deg 89% 21%) 82%,
    hsl(196deg 90% 28%) 91%,
    hsl(197deg 85% 38%) 100%
  );
  color: var(--color-header);
  line-height: 1.7;
  /* background-image: url(../src/assets/blob.svg); */
}

/*-------------------General Styling--------------------*/

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
  /* border: 5px solid red; */
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-header);
}

section > h2 {
  color: var(--color-header);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-header);
}

a {
  color: var(--color-text);
  transition: var(--transition);
  /* font-size: 1rem;
  margin: 0.1rem; */
}

a:hover {
  color: var(--color-header);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-header);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid #e7a42b;
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-bg);
  color: var(--color-header);
  border-color: var(--color-bg-variant);
}

.btn-primary {
  background: var(--color-container);
  color: var(--color-header);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/*///////////////// Media Queries///////////////////*/

/*-----------------------Medium------------------*/

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

/*-----------------------Small------------------*/

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-ms);
  }

  section > h2 {
    margin-bottom: 2rem;
  }
}
