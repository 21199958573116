.about__container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 1rem; /* Adjust this value as needed */
}

#about {
    /* border: 2px solid red; */
    margin-top: 2rem;

    /* padding-top: 2rem; */
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg,
        transparent,
        var(--color-white),
        transparent
    );
    display: flex;
    place-items: center;
    overflow: hidden; /* Ensure the container clips the image properly */
}
.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about__me-image:hover {
    transform: rotate(10deg);
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about__card {
    background: #718e7459;
    border: 1px solid var(--color-header);
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}

.about__card:hover {
    background: transparent;
    border-color: #718e74;
    cursor: text;
}

.about__icon,
.clients__icon,
.projects__icon {
    color: #e7a42b;
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__card h5 {
    font-size: 0.95rem;
}

.about__card small {
    font-size: 0.7rem;
    color: #e7a42b;
}

.about__content p {
    margin: 2rem 0 2.6rem;
    color: #718e7459;
    text-align: center;
}

.about__pitch {
    color: #e7a52bb7;
    background: var(--color-container);
    font-size: 2rem;
    font-style: italic;
    font-weight: 100;
    font-family: "Courier New", Courier, monospace;
    text-align: center;
    margin-bottom: 2rem; /* Adjust this value as needed */
    padding: 4rem 2rem;
    border: 1px solid var(--color-header);
    border-radius: 1rem;
}

.contact-btn {
    display: flex;
    justify-content: center;
}

.btn-contact {
    color: var(--color-header);
    background: var(--color-container);
}

.btn-contact:hover {
    color: var(--color-header);
    background: var(--color-bg);
}
.btn-pitch {
    width: max-content;
    display: inline-block;
    color: var(--color-header);
    background: var(--color---color-bg);
    padding: 0.75rem 1.2rem;
    margin-top: 2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid #e7a42b;
    transition: var(--transition);
}

.btn-pitch:hover {
    color: var(--color-header);
    background: var(--color-bg);
}

/*-----------------------Media Queries Medium---------------*/
@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }

    .about__pitch {
        width: 50%;
        margin: 2rem auto 4rem;
    }
}

/*-----------------------Media Queries Small---------------*/
@media screen and (max-width: 600px) {
    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }
    .about__cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
    .about__content {
        text-align: center;
    }
    .about__content p {
        margin: 1.5rem 0;
    }

    .about__pitch {
        width: 90%;

        text-align: center;
    }
}
