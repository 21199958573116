.container.contact__container {
  width: 58%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact__option {
  background: #718e7459;
  padding: 1.2rem;
  margin-bottom: 0.5rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid var(--color-header);
  transition: var(--transition);
}

.contact__option:hover {
  background: transparent;
  border-color: var(--color-bg-variant);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.9rem;
  color: var(--color-header);
  border: 2px solid var(--color-header);
  border-radius: 1rem;
  padding: 0.4rem;
  font-weight: bold;
  font-style: initial;
}

.contact__option a:hover {
  background: transparent;
  border-color: var(--color-bg-variant);
}

/*----------------------Fomrs-------------------------*/

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  color: var(--color-header);
  border: 2px solid var(--color-header);
  resize: none;
}

/*///////////////// Media Queries///////////////////*/

/*-----------------------Medium------------------*/

@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/*-----------------------Small------------------*/

@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-ms);
  }
}
