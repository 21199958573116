.container portfolio__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg);
    padding: 3.5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-header);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;

    transform: scale(0.7); /* Add this line to scale the image down by 50% */
}

.portfolio__item h3 {
    display: flex;
    justify-content: center;
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

/*-----------------------Media Queries Medium---------------*/
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/*-----------------------Media Queries Small---------------*/
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
