.experience__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.experience__container > div {
    background: #718e7459;
    padding: 2.5rem 5rem;
    border-radius: 2rem;
    border: 1px solid var(--color-header);
    transition: var(--transition);
}

.experience__container > div:hover {
    background: transparent;
    border-color: var(--color-bg-variant);
    cursor: text;
}

.experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-header);
}

.experience__content {
    display: flex;
    flex-wrap: wrap;
    row-gap: 2rem;
}

.experience__details {
    flex: 0 0 calc(50% - 0.5rem);
    gap: 0.5rem;
    padding: 0.5rem;
    /* border: 2px solid red; */
}

.experience__details-icon {
    margin-top: 6px;
    color: var(--color-header);
}

/*///////////////// Media Queries///////////////////*/

@media screen and (min-width: 1024px) {
    .experience__details {
        flex-basis: calc(
            50% - 0.5rem
        ); /* Set flex-basis to 50% for two columns */
    }
}

/*-----------------------Medium------------------*/

@media screen and (max-width: 1024px) {
    .experience__container {
        grid-template-columns: 1fr;
    }

    .experience__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience__content {
        padding: 1rem;
    }
}

/*-----------------------Small------------------*/

@media screen and (max-width: 600px) {
    .experience__container {
        gap: 1rem;
    }

    .experience__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
}
